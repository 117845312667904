<template>
    <div class="bottom-tool-box">
        <img v-if="isAudioOn" @click="audioToggle" src="@/assets/audio-on.png" alt="...">
        <img v-else @click="audioToggle" src="@/assets/audio-off.png" alt="...">

        <img v-if="isSmallScreen" @click="screenToggle" src="@/assets/full-screen.png" alt="...">
        <img v-else @click="screenToggle" src="@/assets/small-screen.png" alt="...">
    </div>
    <BGM ref="bgm" />
</template>

<script>
import { onMounted, ref, watch } from 'vue';
import BGM from "@/components/tool/BGM.vue";
import { useStore } from 'vuex';

export default {
    components: {
        BGM
    },
    setup() {
        const store = useStore();
        const isAudioOn = ref(store.getters.getSound);
        const isSmallScreen = ref(true);
        const bgm = ref(null);

        watch(() => store.getters.getSound, function() {
            if(store.getters.getSound) {
                bgm.value.play();
            }
            else {
                bgm.value.pause();
            }

            isAudioOn.value = store.getters.getSound;
        });

        onMounted(() => {
            if(store.getters.getSound) {
                bgm.value.play();
            }
        });

        return {
            isAudioOn,
            isSmallScreen,
            bgm
        }
    },
    methods: {
        audioToggle() {
            this.isAudioOn = !this.isAudioOn;

            this.$store.dispatch("setSound", {
                sound: this.isAudioOn
            });
        },
        screenToggle() {
            this.isSmallScreen = !this.isSmallScreen;
            if (!document.fullscreenElement) {
                document.documentElement.requestFullscreen();
            }
            else {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                }
            }
        }
    }
}
</script>

<style scoped>
.bottom-tool-box {
    position: absolute;
    bottom: 5%;
    right: 5%;
    z-index: 4;
}
.bottom-tool-box > img {
    width: 32px;
    margin-left: 1rem;
    cursor: pointer;
}
</style>