<template>
    <div class="logo-box">
        <img v-if="isBackKey" @click="back" class="back" src="@/assets/back.png" alt="...">
        <img class="logo" src="@/assets/jeju43-logo.png" alt="..." />
    </div>
</template>

<script>
import { toRefs } from 'vue';

export default {
    props: ["backKey"],
    setup(props) {
        const { backKey } = toRefs(props);
        
        return {
            isBackKey: backKey
        }
    },
    methods: {
        openExternalLink() {
            window.open("https://www.jeju.go.kr");
        },
        back() {
            this.$router.push("/");
        }
    }
}
</script>

<style scoped>
.logo-box {
    position: absolute;
    top: 5%;
    left: 5%;
    z-index: 999;
    /* cursor: pointer; */
    display: flex;
}
.logo-box > .logo {
    width: 250px;
}

.back {
    width: 10px;
    margin-right: 1rem;
    cursor: pointer;
}

@media (min-width: 1281px) {

}

@media (min-width: 1025px) and (max-width: 1280px) {
    .logo-box > .logo {
        width: 150px;
    }
    .back {
        width: 5px;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .logo-box > .logo {
        width: 130px;
    }
    .back {
        width: 5px;
    }
}

@media (min-width: 481px) and (max-width: 767px) {
    .logo-box > .logo {
        width: 130px;
    }
    .back {
        width: 5px;
    }
}

@media (max-width: 481px) {
    .logo-box > .logo {
        width: 130px;
    }
    .back {
        width: 5px;
    }
}
</style>