<template>
    <div class="modal-container">
        <div class="close-box" @click="closeModal">
            <img src="@/assets/exit.png" alt="...">
        </div>
        <div id="modalBox" class="modal-box mb-4">
            <h3 class="mb-4 title">{{text[setLang].title}}</h3>

            <div class="link-list">
                <div class="link-box mt-3" v-for="link in linkList" :key="link._id" @click="openExternalLink(link.link)">
                    <img :src="link.metaData.thumbnailUrl" alt="...">
                </div>
            </div>
        </div>
        <div v-if="isOverFlow" class="scroll-down" @click="scrollDown">
            <img src="@/assets/scroll-down.png" alt="...">
        </div>
    </div>
</template>

<script>
import lang from '@/lang/language';
import { onMounted, ref } from 'vue';
import axios from "axios";

export default {
    setup() {
        const linkList = ref([]);
        const isOverFlow = ref(false);

        onMounted(async() => {
            const url = process.env.VUE_APP_API_HOST + "scene/external-link/list";
            const result = await axios.get(url);

            if(result.data.success) {
                linkList.value = result.data.data;

                linkList.value.forEach((link) => {
                    link.metaData.thumbnailUrl = process.env.VUE_APP_API_HOST + link.metaData.thumbnailUrl;
                });
            }

            const container = document.getElementById ("modalBox");
            console.log(container.scrollHeight, self.innerHeight)
            if(container.scrollHeight > self.innerHeight) {
                isOverFlow.value = true;
            }
        });

        return {
            text: lang.linkModal,
            linkList
        }
    },
    computed: {
        setLang() {
            return this.$store.state.user.lang;
        }
    },
    methods: {
        closeModal() {
            this.$emit("closeFromChild");
        },
        openExternalLink(link) {
            window.open(link);
        }
    },
}
</script>

<style scoped>
.modal-container {
    position: absolute;
    right: 0px;
    top: 0px;
    height: 100%;
    width: 35%;
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    background-color: rgb(0,0,0,0.2);
    border-radius: 10px;
    z-index: 1000;
}
.modal-box {
    padding-top: 4rem;
    padding-left: 2rem;
    padding-right: 2rem;
    text-align: left;
    color: white;
    height: 100%;
}
.modal-box > img {
    width: 100%;
}
.title {
    font-weight: bold;
}
.scroll-down {
    position: absolute;
    bottom: 0px;
    background-image: linear-gradient(to bottom, rgba(19, 19, 19, 0), #333);
    width: 100%;
    padding: 0px;
}
.scroll-down > img {
    width: 40px;
}
.close-box {
    position: absolute;
    top: 2%;
    right: 2%;
    cursor: pointer;
}
.close-box > img {
    width: 25px;
    height: 25px;
}
.link-list {
    overflow: hidden;
    height: 100%;
}
.link-list:hover {
    overflow-y: auto;
}
.link-list::-webkit-scrollbar {
    display: none;
}
.link-box {
    width: 100%;
    background-color: white;
    padding: 0.5rem;
    color: black;
    border-radius: 19px;
    cursor: pointer;
}
.link-box > img {
    max-height: 50px;
    margin-right: 2rem;
}

@media (min-width: 1281px) {

}

@media (min-width: 1025px) and (max-width: 1280px) {
    .modal-container {
        width: 50%;
    }
    .link-box > img {
        width: 50%;
    }
    .link-list {
        height: 80%;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .modal-container {
        width: 50%;
    }
    .link-box > img {
        width: 50%;
    }
    .link-list {
        height: 80%;
    }
}

@media (min-width: 481px) and (max-width: 767px) {
    .modal-container {
        width: 100%;
    }
    .link-box > img {
        width: 50%;
    }
    .link-list {
        height: 80%;
    }
}

@media (max-width: 481px) {
    .modal-container {
        width: 100%;
    }
    .link-box > img {
        width: 50%;
    }
}
</style>