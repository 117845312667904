<template>
    <audio id="audio" loop>
        <source src="@/assets/bgm.mp3" type="audio/mpeg">
    </audio>
</template>

<script>
export default {
    methods: {
        play() {
            const audio = document.getElementById("audio");
            if(this.$route.path === "/world/lobby" && !audio.src.includes("/assets/world-bgm.mp3")) {
                audio.src = "/assets/world-bgm.mp3"
            }
            audio.play();
        },
        pause() {
            document.getElementById("audio").pause();
        }
    }
}
</script>