<template>
    <div class="top-tool-box">
        <img v-if="isLink" class="link-index" @click="toggleLinkModal" src="@/assets/link.png" alt="...">
        <img v-if="isLang" class="lang-index" @click="toggleLangBox" src="@/assets/language.png" alt="...">
        <div v-if="isLangBoxOpen" class="lang-box lang-index">
            <h5 @click="changeLang('ko')">KO</h5>
            <h5 @click="changeLang('en')">EN</h5>
            <h5 @click="changeLang('cn')">CN</h5>
            <h5 @click="changeLang('jp')">JP</h5>
        </div>
    </div>

    <Transition name="slide-fade">
        <LinkModal v-if="isModalOpen" @closeFromChild="toggleLinkModal" />
    </Transition>
</template>

<script>
import { ref, toRefs } from 'vue';
import LinkModal from "@/components/modal/LinkModal.vue";

export default {
    props: ["link", "lang"],
    components: {
        LinkModal
    },
    setup(props) {
        const isLangBoxOpen = ref(false);
        const isModalOpen = ref(false);
        const {link, lang} = toRefs(props);

        return {
            isLangBoxOpen,
            isModalOpen,
            isLink: link,
            isLang: lang
        }
    },
    methods: {
        toggleLangBox() {
            this.isLangBoxOpen = !this.isLangBoxOpen;
        },
        changeLang(lang) {
            this.$store.dispatch("setLang", {
                lang: lang
            });

            this.toggleLangBox();
        },
        toggleLinkModal() {
            this.isModalOpen = !this.isModalOpen;
        }
    }
}
</script>

<style scoped>
.top-tool-box {
    position: absolute;
    top: 5%;
    right: 5%;
}
.link-index {
    position: relative;
    z-index: 2;
}
.lang-index {
    position: relative;
    z-index: 4;
}
.top-tool-box > img {
    width: 32px;
    cursor: pointer;
    margin-left: 0.5rem;
}
.lang-box {
    position: absolute;
    /* right: 2.5%; */
    border: solid 1px white;
    border-radius: 10px;
    background-color: rgb(255, 255, 255);
    color: black;
    padding: 1rem 2rem 0.5rem 1.5rem;
    margin-top: 0.6rem;
    text-align: center;
}
.lang-box > h5 {
    cursor: pointer;
}
.lang-box > h5:hover {
    color: #e0501e;
}
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

@media (min-width: 1281px) {

}

@media (min-width: 1025px) and (max-width: 1280px) {

}

@media (min-width: 768px) and (max-width: 1024px) {

}

@media (min-width: 481px) and (max-width: 767px) {

}

@media (max-width: 481px) {
    
}
</style>